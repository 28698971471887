
/* import Cookie from '~/components/Cookie.vue' */
import TopHeader from '~/components/TopHeader.vue'
import BottomFooter from '~/components/BottomFooter.vue'
import AsBanner from '~/components/AsBanner.vue'

export default {
  components: {
    TopHeader,
    BottomFooter,
    AsBanner
    /* Cookie */
  },
  data() {
    return {
      isBlured: false,
      loadNav: false
    }
  },
  created() {
    /* if (process.browser) {
      this.checkGDPR()
    } */
  },
  methods: {
    loadNavTriggger() {
      this.loadNav = true
    }
    /* checkGDPR() {
      this.isBlured = !this.getGDPR()
    },
    getGDPR() {
      if (process.browser) {
        return localStorage.getItem('GDPR:accepted', true)
      }
    } */
  }
}
